<template>
  <div class="newsContent">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack()">资讯详情</div>
    <div class="card">
        <div class="newtitle">{{detail.noticeTheme}}</div>
        <div class="newtime flex">
              <img src="@/assets/img/time.png">
              <span style="margin-right: 1vw">{{detail.publishTime}}</span>            
              <img src="@/assets/img/link.png">
              <span>{{detail.orgName ? detail.orgName : '桂信融'}}</span>            
        </div>
        <div class="card-html" v-html="replacePath(detail.noticeContent)"></div>
        <!-- <div class="newtext">目前，‘桂信融’依托数字政府政务大数据中心汇聚的235.14亿条政务数据，全面连通人口、法人、社会信用、电子证照、空间地理信息等核心基础数据库，为每个市场主体打造唯一的‘桂信码’。</div>
        <div class="newtext">企业办事一码通行，免证营商更加便利。在日常商务交往时，企业可互扫桂信码查看对方商事登记、社会信用、供需情况、中标经历等信息，一扫“知根知底”；在交易场合，企业通过“桂信码”即可在线领取发票，也可即时开出电子发票，节省时间和人力</div>
        <img src="@/assets/img/newc.png">
        <div class="newtext">在交易场合，企业通过“桂信码”即可在线领 取发票，也可即时开出电子发票，节省时间和 人力。 </div> -->
        <div v-if="detail.attachmentName" class="accessory">附件：<span>{{detail.attachmentName}}</span></div>
    </div>

    
    <!-- <div style="height: 15vw; width:100%"></div> -->
    <!-- <TabBar nav="news"/> -->
  </div>
</template>
<script>
// import TabBar from '@/components/TabBar.vue';
import { useRouter } from 'vue-router';
import { apiNoticeDetail } from '@/api/axios';
import { Toast } from 'vant';
import { ref } from 'vue';
import { useStore } from 'vuex';
export default {
   setup() {
     let router = useRouter();
     let store = useStore();
     let id = router.currentRoute.value.params.id;
     const detail = ref({});
     apiNoticeDetail({noticeId: id}).then(res => {     
       console.log(res)            
             if(res.code == '1'){
                 detail.value = res.info.noticeDetail;
              }
              else {
                Toast(res.msg);
              }
          });
    const replacePath = (str) => {
            let reg = new RegExp('uploadFiles/',"g")
            let newstr=str.replace(reg,store.state.imgURL2+'uploadFiles/');
            return newstr;
        }
    const goBack = () => {
          router.push({ name:'news',params:{ type:3},replace:true}); 
      }
    return { 
      detail,
      goBack,
      replacePath

     };
  },
  // components: {
  //   TabBar
  // }
}
</script>
<style lang="less" scoped>
.newsContent {
    padding-top: 12.5vw;
    .card {
        .newtitle {
            font-size: 5vw;
            font-weight: 500;
        }
        .newtime {
            font-size: 3.3vw;
            color: #ccc;
            padding: 4vw 0;
            img {
            width: 3.5vw;
            height: auto;
            margin-right: 1vw;
            }
        }
        .newtext {
            font-size: 4vw;
            margin: 4vw 0;
            line-height: 7vw;
        }
        img {
            width: 100%;
            height: auto;
        }
        .accessory {
          font-size: 4vw;
          word-break: break-all;
          span {
            color: rgb(19, 100, 250);
          }
        }
    }
}
</style>
